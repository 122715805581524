<template>
  <div>
    <v-row>
      <v-col class="text-left page-title ml-16">
        My {{ $route.params.type === 'book' ? 'page' : $route.params.type }}s
      </v-col>
    </v-row>
    <v-container>

      <v-row>
        <v-col v-for="bookmark in bookmarks" :key="bookmark.id" sm="4">
          <product-card :product="bookmark"></product-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard";

export default {
  name: "Bookmarks",
  components: {ProductCard},
  data() {
    return {
      bookmarks: [],
    }
  },
  mounted() {
    window.axios.get(`api/bookmarks?type=` + this.$route.params.type,)
        .then((data) => {
          this.bookmarks = data.data.data
        })
  },
}
</script>

<style scoped>
.page-title {
  font-family: Blinker-Bold, serif;
  font-size: 40px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 1;
  line-height: normal;
  letter-spacing: normal;
  color: #ffbe66;
}
</style>